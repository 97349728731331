import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useMemo } from 'react';

import { publicRuntimeConfig } from 'lib/utils/Environment';

export const UserSwitcher = dynamic(() => import('@shared-oneflare-components/UserSwitcher'), {
  ssr: false
});

type HeadContentProps = {
  pageUrl: string;
  bottomHead?: React.ReactNode;
  isBot?: boolean;
  metaDescription?: string;
  metaNoFollow?: boolean;
  metaNoIndex?: boolean;
  ogDescription?: string;
  ogImage?: string;
  ogTitle?: string;
  ogUrl?: string;
  pageShareImg?: string;
  pageTitle?: string;
  prefetchHero?: string;
  topHead?: React.ReactNode;
  tracking?: {
    environment?: string;
    deploymentVersion?: string;
    locationId?: string;
    categoryId?: string;
    categoryName?: string;
    zoneId?: string;
    region_id?: string;
    state_id?: string;
  };
  upgradeInsecureRequests?: boolean;
};

const HeadContent = ({
  pageUrl,
  pageTitle = 'Oneflare: Connecting Australians With Experts & Businesses',
  metaDescription = '',
  pageShareImg = 'https://cdn.oneflare.com/static/site/home/facebook/logo-icon-large.png',
  tracking = {},
  metaNoFollow = false,
  metaNoIndex = false,
  bottomHead = null,
  topHead = null,
  ogTitle = '',
  ogDescription = '',
  ogImage = 'https://cdn.oneflare.com/static/site/home/facebook/logo-icon-large.png',
  ogUrl = '',
  isBot = false,
  upgradeInsecureRequests = false,
  prefetchHero = ''
}: HeadContentProps) => {
  const otherMetaTag = useMemo(() => {
    if (!metaNoIndex && !metaNoFollow) return null;
    let contentValue = '';
    if (metaNoIndex && metaNoFollow) {
      contentValue = 'noindex, nofollow';
    } else if (metaNoIndex) {
      contentValue = 'noindex';
    } else {
      contentValue = 'nofollow';
    }
    return <meta name="robots" content={contentValue} />;
  }, [metaNoIndex, metaNoFollow]);

  return (
    // Page landing datalayer script is declared as a helmet prop
    // because it needs to be fired before the GTM start event.
    <>
      <Head>
        <title>{pageTitle}</title>
        {prefetchHero && (
          <>
            <link rel="preconnect" href={prefetchHero} />
            <link rel="dns-prefetch" href={prefetchHero} />
          </>
        )}
        <link rel="preconnect" href="https://bam.nr-data.net" />
        <link rel="preconnect" href="https://stats.g.doubleclick.net" />
        <link rel="preconnect" href="https://bat.bing.com" />
        <link rel="preconnect" href="https://d3aetuyv665xyv.cloudfront.net" />
        <link rel="preconnect" href="https://www.facebook.com" />
        <link rel="preconnect" href="https://connect.facebook.net" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        {topHead}
        <link rel="canonical" href={pageUrl} />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://cdn.oneflare.com/static/client/oneflare/favicon-57x57.png"
          sizes="57x57"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://cdn.oneflare.com/static/client/oneflare/favicon-72x72.png"
          sizes="72x72"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://cdn.oneflare.com/static/client/oneflare/favicon-76x76.png"
          sizes="76x76"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://cdn.oneflare.com/static/client/oneflare/favicon-114x114.png"
          sizes="114x114"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://cdn.oneflare.com/static/client/oneflare/favicon-144x144.png"
          sizes="144x144"
        />
        <link
          rel="apple-touch-icon"
          type="image/x-icon"
          href="https://cdn.oneflare.com/static/client/oneflare/favicon-152x152.png"
          sizes="152x152"
        />
        <link
          rel="apple-touch-icon"
          type="image/x-icon"
          href="https://cdn.oneflare.com/static/client/oneflare/favicon-180x180.png"
          sizes="180x180"
        />
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        <meta property="og:url" content={ogUrl || pageUrl} />
        <meta property="og:image" content={ogImage || pageShareImg} />
        <meta property="og:title" content={ogTitle || pageTitle} />
        {metaDescription && (
          <meta
            property="og:description"
            content={ogDescription || metaDescription}
          />
        )}

        {upgradeInsecureRequests && (
          <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
        )}
        {otherMetaTag}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
            `
          }}
        />
        {tracking.environment && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(${JSON.stringify(tracking)});
                `
            }}
          />
        )}
        {bottomHead}
      </Head>
      {!isBot && publicRuntimeConfig.ENVIRONMENT !== 'production' && <UserSwitcher currentUrl={pageUrl} />}
    </>
  );
};

export default HeadContent;
