import Observer from '@researchgate/react-intersection-observer';
import PropTypes from 'prop-types';
import { useState } from 'react';

const propTypes = {
  autoLoad: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  observerOptions: PropTypes.objectOf(PropTypes.string)
};

const defaultProps = {
  autoLoad: false,
  children: null,
  observerOptions: {}
};

const Lazy = ({ autoLoad, children, observerOptions }) => {
  const [loaded, setLoaded] = useState(autoLoad);
  const handleIntersection = (event, unobserve) => {
    if (event.isIntersecting) {
      // Once an intersection is detected, there's no need to keep observing
      unobserve();
      setLoaded(true);
    }
  };

  const options = {
    onChange: handleIntersection,
    ...observerOptions
  };

  return (
    <Observer {...options}>
      {children(loaded)}
    </Observer>
  );
};

Lazy.propTypes = propTypes;
Lazy.defaultProps = defaultProps;

export default Lazy;
