import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const HomepageHeroStyled = styled.section`
  background: ${({ theme }) => theme.color.cloud} url('https://cdn.oneflare.com/static/client/hero/home-hero-${({ $rndNumber }) => $rndNumber}-mobile.jpg') bottom center no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: start;
  justify-content: center;
  min-height: 650px;
  max-height: 1500px;
  position: relative;
  margin: 0 0 40px;

  ${media.smUp`
    background: ${({ theme }) => theme.color.cloud} url('https://cdn.oneflare.com/static/client/hero/home-hero-${({ $rndNumber }) => ($rndNumber)}.jpg') bottom center no-repeat;
    background-size: cover;
  `}

  ${media.mdUp`
    margin: 0 0 64px;
  `}

  ${media.lgUp`
    margin: 0 0 80px;
  `}
`;

export const GetFreeQuotesWrapperStyled = styled.div`
  max-width: 1200px;
  flex: 1;
  position: relative;
  top: 76px;
  margin: 0 20px;
  padding-top: 40px;

  ${media.smUp`
    padding-top: 0;
    top: 112px;
  `}

  ${media.mdUp`
    margin: 0 40px;
  `}

  ${media.lgUp`
    top: 148px;
  `}
`;

export const NetworkStyled = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.cloud};
  position: absolute;
  bottom: 0;

  ${media.mdUp`
    padding: 0 20px;
  `}
`;
