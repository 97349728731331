import H2 from '@oneflare/flarekit/lib/components/H2';
import H3 from '@oneflare/flarekit/lib/components/H3';
import H4 from '@oneflare/flarekit/lib/components/H4';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import dynamic from 'next/dynamic';
import { styled, css } from 'styled-components';

const Avatar = dynamic(() => import('@oneflare/flarekit/lib/components/Avatar'), {
  ssr: false
});

export const MarqueeWrapper = styled.section`
  .right {
    display: none;

    ${media.smUp`
      display: flex;
    `}
  }
`;

const baseTextStyles = css`
  margin: 0;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const WhatsLiveWrapper = styled.section`
  margin: 80px auto 0;
  min-height: 274px;
  max-width: 2800px;

  ${media.smUp`
    min-height: 499px;
    margin: 128px auto 0;
  `}

  ${media.lgUp`
   margin: 160px auto 0;
  `}
`;

export const WhatsLiveTitleStyled = styled(H2)`
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};
  margin: 0 auto;
  padding: 0 20px 24px;
  max-width: 1120px;

  ${media.mdUp`
    padding: 0 40px 12px;
  `}

  ${media.xlUp`
    padding: 0 0 12px;
 `}
`;

export const JobStyled = styled.div`
  width: 260px;
  height: 182px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.nickel};
  border-radius: 4px;
  margin: 20px 20px 0 0;
  position: relative;
  transition: top .2s ease-in;
  top: 0;

  &:hover {
    box-shadow: 1px 1px 4px 0 rgba(38, 49, 51, 0.08);
    border: solid 1px ${({ theme }) => theme.color.nickel};
    top: -12px;

    /*
       Hack to fix the flickering issue on hover
       https://dev.to/linxea/css-flicker-on-hover-5gj5
    */

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -12px;
      height: 12px;
      width: 100%;
      box-sizing: border-box;
    }
  }

`;

export const AvatarStyled = styled(Avatar)`
  top: 3px;
  left: 0;
  padding: 0 0 8px;
`;

export const JobCategoryStyled = styled(H3)`
  ${baseTextStyles};

  font-family: ${({ theme }) => theme.font.secondaryBold};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.silver};
  font-size: 11px;
  padding: 0 0 8px;
`;

export const JobStyleHeader = styled.div`
  position: relative;
  padding: 0 0 8px;

  div:first-child {
    position: absolute;
  }
`;

export const JobUsersName = styled(H4)`
  ${baseTextStyles};

  padding: 0 0 0 56px;
  margin: 0;
  font-family: ${({ theme }) => theme.font.primaryRegular};
  color: ${({ theme }) => theme.color.charcoal};
  font-size: 16px;
  font-weight: bold;
`;

export const JobUsersLocation = styled.div`
  ${baseTextStyles};

  padding: 0 0 0 56px;
  margin: 0;
  color: ${({ theme }) => theme.color.spaceGrey};
  font-size: 14px;
`;

export const JobDescription = styled(Paragraph)`
  color: ${({ theme }) => theme.color.gunMetal};
  font-size: 16px;
  line-height: 1.5;
  height: 45px;
  /* stylelint-disable */
  /* Line camp will ensure the description will only span two lines,
     anything longer an ellipsis will be added. Height was added to
     cater for IE11 which doesn't support line clamping.
   */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  overflow: hidden;
  margin: 0 0 8px;
`;

export const JobPosted = styled.div`
  color: ${({ theme }) => theme.color.spaceGrey};
  font-size: 13px;
  line-height: 1.5;
`;
