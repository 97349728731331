import AutoComplete from '@oneflare/flarekit/lib/components/AutoComplete';
import Button from '@oneflare/flarekit/lib/components/Button';
import H1 from '@oneflare/flarekit/lib/components/H1';
import H2 from '@oneflare/flarekit/lib/components/H2';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled, css } from 'styled-components';

export type FreeQuotesFormKind = 'default' | 'narrow';

export const GetFreeQuotesForm = styled.div<{
  $kind?: FreeQuotesFormKind;
}>`
  ${({ $kind }) => $kind === 'default' && media.smUp`
    max-width: 422px;
    border-radius: 8px;
    box-shadow: 2px 2px 4px 0 rgba(38, 49, 51, 0.05);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 40px;
  `}

  ${({ $kind }) => $kind === 'default' && media.lgUp`
    display: flex;
    flex-wrap: wrap;
    max-width: 580px;
  `}
`;

const getFreeQuotesTitleStyles = css`
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};
  text-align: center;
`;

export const GetFreeQuotesTitle = styled(H1)`
  ${getFreeQuotesTitleStyles};

  font-size: 40px;
  margin-bottom: 30px;

  ${media.smUp`
    font-size: 28px;
  `}

  ${media.lgUp`
    font-size: 40px;
    margin-bottom: 32px;
  `}
`;

export const GetFreeQuotesDowngradedTitle = styled(H2)`
  ${getFreeQuotesTitleStyles};

  font-size: 18px;
  margin-bottom: 8px;

  ${media.lgUp`
    text-align: left;
  `}
`;

export const SubTitle = styled(Paragraph)`
  text-align: center;
  margin-bottom: 16px;

  ${media.lgUp`
    text-align: left;
  `}
`;

export const ServiceInput = styled(AutoComplete)<{
  $kind?: FreeQuotesFormKind;
}>`
  ${({ $kind }) => $kind === 'default' && media.lgUp`
    width: 240px;
    margin: 0 16px 0 0;
  `}
`;

export const LocationInput = styled(AutoComplete)<{
  $kind?: FreeQuotesFormKind;
}>`
  margin: 24px 0 0;

  ${({ $kind }) => $kind === 'default' && media.lgUp`
    width: 168px;
    margin: 0;
  `}
`;

export const GetFreeQuotesButton = styled(Button)<{
  $kind?: FreeQuotesFormKind;
}>`
  margin: 24px 0 0;
  width: 100%;

  ${({ $kind }) => ($kind === 'narrow'
    ? media.lgUp`
      width: auto;
    `
    : media.smUp`
      width: auto;
    `
  )}
`;
